/* Wrapper container for entire page */
html,
body {
  margin: 0;
  padding: 0;
  height: 100%; /* Ensure the body takes full height of the viewport */
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the container fills the viewport height */
}

/* Footer specific styles */
footer {
  margin-top: auto; /* Push the footer to the bottom of the page */
  background-color: #002538; /* Existing background color */
  color: white; /* Text color */
}

button {
    padding: 0.8em 1.8em;
    /* border: 2px solid #17C3B2; */
    position: relative;
    overflow: hidden;
    background-color: transparent;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    transition: .3s;
    z-index: 1;
    font-family: inherit;
    /* color: #17C3B2; */
   }
   
   button::before {
    content: '';
    width: 0;
    height: 300%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    /* background: #17C3B2; */
    transition: .5s ease;
    display: block;
    z-index: -1;
   }
   
   button:hover::before {
    width: 105%;
   }
   
   button:hover {
    color: #111;
   }
   