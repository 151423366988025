/* From Uiverse.io by PriyanshuGupta28 */
.spinner {
    width: 56px;
    height: 56px;
    display: grid;
    border-radius: 50%;
    -webkit-mask: radial-gradient(farthest-side,#0000 40%,#ffa500 41%); /* Use orange color */
    background: linear-gradient(0deg ,rgba(255,165,0,0.5) 50%,rgba(255,165,0,1) 0) center/4.5px 100%, /* Orange gradient */
          linear-gradient(90deg,rgba(255,165,0,0.25) 50%,rgba(255,165,0,0.75) 0) center/100% 4.5px; /* Orange gradient */
    background-repeat: no-repeat;
    animation: spinner-d3o0rx 1s infinite steps(12);
}

.spinner::before,
.spinner::after {
    content: "";
    grid-area: 1/1;
    border-radius: 50%;
    background: inherit;
    opacity: 0.915;
    transform: rotate(30deg);
}

.spinner::after {
    opacity: 0.83;
    transform: rotate(60deg);
}

@keyframes spinner-d3o0rx {
    100% {
      transform: rotate(1turn);
    }
}
