/* Customize the scrollbar */
::-webkit-scrollbar {
    width: 5px;
      /* Adjust the width of the scrollbar */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: rgb(255, 115, 0); /* Set the color of the scrollbar thumb */
    border-radius: 10px; /* Optional: Add rounded corners */
  }
  
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Set the background color of the scrollbar track */
    border-radius: 10px; /* Optional: Add rounded corners */
  }


  