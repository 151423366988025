/* From Uiverse.io by Smit-Prajapati */
.parent {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Prevents cards from overlapping */
}

.card {
  padding-top: 50px;
  border: 3px solid rgb(255, 255, 255);
  transform-style: preserve-3d;
  background: linear-gradient(135deg, #0000 18.75%, #f3f3f3 0 31.25%, #0000 0),
    repeating-linear-gradient(45deg, #f3f3f3 -6.25% 6.25%, #ffffff 0 18.75%);
  background-size: 60px 60px;
  background-position: 0 0, 0 0;
  background-color: #f0f0f0;
  flex-basis: calc(33.333% - 20px); /* Responsive width */
  max-width: 33.333%;
  margin: auto;
  box-shadow: rgba(142, 142, 142, 0.3) 0px 30px 30px -10px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.content-box {
  background: rgb(249 115 22);
  transition: all 0.5s ease-in-out;
  padding: 50px 20px 20px 20px;
  transform-style: preserve-3d;
  min-height: 230px; /* Prevents content overlap */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Fix for title: Allow full visibility without overflow */
.content-box .card-title {
  display: block;
  color: white;
  font-size: 20px;
  font-weight: 900;
  white-space: normal; /* Allows wrapping */
  word-wrap: break-word;
  overflow: visible;
  max-width: 100%;
  max-height: 60px; /* Allows multi-line titles */
  line-height: 1.2;
}

.content-box .card-content {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 700;
  color: #f2f2f2;
  flex-grow: 1; /* Ensures content does not push other elements */
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 60px; /* Limits text height */
}

.content-box .see-more {
  position: relative;
  cursor: pointer;
  font-weight: 900;
  font-size: 10px;
  text-transform: uppercase;
  color: rgb(249 115 22);
  background: white;
  padding: 0.5rem 0.7rem;
  transition: all 0.5s ease-in-out;
  align-self: flex-start; /* Keeps button inside content */
}

.date-box {
  position: absolute;
  top: 15px;
  right: 15px;
  height: 50px;
  width: 50px;
  
  background: white;
  border: 1px solid rgb(249 115 22);
  padding: 5px;
  transform: translate3d(0px, 0px, 80px);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 17px 10px -10px;
}

.date-box .date {
  font-size: 16px;
  font-weight: 900;
  color: rgb(249 115 22);
}

/* Responsive Fixes */
@media (max-width: 1024px) {
  .card {
    flex-basis: calc(50% - 20px);
    max-width: 50%;
  }

  .content-box {
    padding: 45px 15px 15px 15px;
  }

  .content-box .card-title {
    font-size: 18px;
    max-height: 80px; /* Allows more space for title */
  }
}

@media (max-width: 768px) {
  .card {
    flex-basis: calc(100% - 20px);
    max-width: 100%;
  }

  .content-box .card-title {
    font-size: 18px;
    max-height: 90px; /* Allows wrapping */
  }

  .content-box .card-content {
    font-size: 12px;
    max-height: 50px; /* Keeps text from expanding too much */
  }

  .see-more {
    font-size: 9px;
    padding: 0.4rem 0.6rem;
  }
}

@media (max-width: 576px) {
  .card {
    width: 100%;
  }
}


.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
}

.popup-content h3 {
  margin-bottom: 10px;
}

.popup-content input {
  width: 80%;
  padding: 8px;
  margin-bottom: 10px;
}

.popup-content button {
  padding: 8px 16px;
  margin: 5px;
  background-color: #f8903b;
  color: white;
  border: none;
  cursor: pointer;
}

.popup-content button:hover {
  background-color: #ea6341;
}




/* ************************************  This Css For Pyramid Ui  **********************************************************/
.pyramid-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align everything to the left */
  margin-top: 20px;
  padding-left: 20px; /* Adjust padding for left alignment */
}
.pyramid-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Keep pyramid numbers centered */
  margin-top: 10px;
}
.pyramid-row {
  display: flex;
  justify-content: center; /* Center pyramid numbers */
  margin: 5px 0;
  width: 100%;
}
.pyramid-number {
  background: gold;
  color: black;
  font-size: 18px;
  font-weight: bold;
  padding: 8px 12px;
  margin: 3px;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
.matched-data {
  margin-top: 20px;
  text-align:start; /* Align matched data to the left */
}
