/* From Uiverse.io by nikk7007 */
button {
  padding: 0.8em 1.8em;
  border: 2px solid rgb(249 115 22);
  position: relative;
  overflow: hidden;
  background-color: transparent;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  transition: 0.3s;
  z-index: 1;
  font-family: inherit;
  color: rgb(249 115 22);
  font-weight: bold;
}

button::before {
  content: "";
  width: 0;
  height: 300%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: rgb(249 115 22);
  transition: 0.5s ease;
  display: block;
  z-index: -1;
}

button:hover::before {
  width: 105%;
}

button:hover {
  color: #ffffff;
}

/* Style for Slick dots */
.slick-dots li {
  margin: 0 5px !important; /* Adds consistent spacing between dots */
}

.slick-dots li button {
  width: 12px !important; /* Adjust size of the dots */
  height: 12px !important;
  border-radius: 50% !important; /* Makes the dots circular */
  background-color: rgb(
    128,
    128,
    128
  ) !important; /* Default color for inactive dots */
  border: none !important; /* Removes any border if present */
  cursor: pointer !important; /* Adds pointer for better UX */
}

/* Highlight active dot */
.slick-dots li.slick-active button {
  background-color: rgb(245, 119, 1) !important; /* Active dot color */
}

/* Center the dots and adjust spacing */
.slick-dots {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 2px !important; /* Proper spacing between dots */
  padding: 10px 0 !important; /* Add some padding around the dots */
}
